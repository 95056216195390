<template>
  <b-modal v-model="dialog.active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>{{ msg("Add Guest Feature") }}</template>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="feature.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Name')">
                    <b-input v-model="feature.name" placeholder="Name"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Description')">
                    <b-textarea v-model="feature.description" placeholder="Description"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="feature.imageUri" placeholder="Image Uri"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="allBranches.length > 1">
          <b-form-group :label="msg('Branch Ids')">
            <v-select
                multiple
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="feature.branchIds"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col cols="6">{{msg('Translations')}}</b-col>
            <b-col cols="6"  >
              <div class="text-right" >
                <b-dropdown class="d-inline-block"  size="sm" text="Select Language" >
                  <b-dropdown-item-button v-for="lang in allowedLanguages" @click="addTranslation(lang.code)" v-bind:key="lang.code">
                    {{lang.name}}
                  </b-dropdown-item-button>
                  <!-- Add more languages as needed -->
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
          <b-table small striped hover :items="translations" :fields="translationFields">
            <template #cell(language)="data">
              <b-button variant="link" style="padding: 5px" @click="removeTranslation(data.item.language)">
                <i class="fa-solid fa-xmark"></i>
              </b-button>
              {{data.item.language}}
            </template>
            <template #cell(name)="data">
              <b-input v-model="data.item.name" placeholder="Name"/>
            </template>
            <template #cell(description)="data">
              <b-textarea v-model="data.item.description" placeholder="Description"/>
            </template>
          </b-table>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.amountAllowed">
              {{ msg('Amount Allowed') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.autoApprove">
              {{ msg('Auto Approve') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.dailyPayment">
              {{ msg('Daily Payment') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.active">
              {{ msg('Active') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-btn block @click="reset" variant="outline-primary" v-bind:disabled="loading">{{ msg('Cancel') }}</b-btn>
        </b-col>
        <b-col cols="6">
          <b-btn block @click="saveFeature" variant="primary">{{ msg('Save') }}</b-btn>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

const getFeature = () => {
  return {
    name: null, description: null, icon: null, imageUri: null,
    amountAllowed: false, autoApprove: true,
    dailyPayment: false, branchIds: [], active: true, translations: {}
  }
}

const allLanguages = [{code: 'en', name: 'English'}, {code: 'de', name: 'German'}, {code: 'ru', name: 'Russian'}];

export default {
  name: "GuestFeatureModal",
  data: () => ({
    filter: {},
    page: {amount: 10, currentPage: 1, total: 0},
    dialog: {
      active: false,
    },
    loading: false,
    feature: getFeature(),
  }),
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    translations() {
      return Object.values(this.feature?.translations || {});
    },
    allowedLanguages(){
      return allLanguages.filter(lang => this.feature.translations[lang.code] == null);
    },
    translationFields() {
      return [
        { key: 'language', label: this.msg('language') },
        { key: 'name', label: this.msg('Name') },
        { key: 'description', label: this.msg('Description') },
      ]
    }
  },
  methods: {
    ...mapActions('guestFeature', ['insertGuestFeature', 'updateGuestFeature', 'fetchGuestFeatureById']),

    addNewFeature() {
      this.feature = getFeature();
      this.feature.branchIds = this.allBranches.map(branch => branch.id);
      this.dialog.active = true;
    },
    updateFeature(featureId) {
      console.log("updateFeature", featureId)
      let $this = this;
      $this.loading = true;
      this.fetchGuestFeatureById(featureId).then(data => {
        $this.feature = {...getFeature(), ...data.GuestFeature};
        $this.dialog.active = true;
      }).finally(() => {
        $this.loading = false;
      })
    },
    addTranslation(lang) {
      if(this.feature.translations == null)
        this.feature.translations = {};
      let translations = this.feature.translations;
      translations[lang] = { name: '', description: '', language: lang };
      this.feature.translations = {...translations};
    },
    removeTranslation(lang) {
      if(this.feature.translations == null)
        this.feature.translations = {};
      let translations = this.feature.translations;
      delete translations[lang];
      this.feature.translations = {...translations};
    },
    reset() {
      this.feature = getFeature();
    },
    saveFeature() {
      let $this = this;
      this.loading = true;
      let promise = null;
      if (this.feature.id == null) {
        promise = this.insertGuestFeature(this.feature);
      } else {
        promise = this.updateGuestFeature({id: this.feature.id, request: this.feature});
      }
      promise.then(() => {
        $this.dialog.active = false;
        $this.$emit('updated')
        $this.reset();
      }).finally(() => {
        $this.loading = false;
        $this.$emit('updated')
      })
    }
  }
}
</script>


<style scoped>

</style>
