<template>
  <div>
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!--Copied from realEstateContracts.vue-->
        </b-row>
      </b-card-body>
    </b-card>

    <!--Card Content-->
    <b-card no-body>
      <b-card-header style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Guest Feature') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="addNewFeature()">
              <feather-icon icon="PlusIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </b-card-header>

      <!--Table-->
      <b-table
          ref="feature-table"
          striped
          hover
          responsive
          v-bind:items="features"
          v-bind:fields="fields"
          :busy="loading"
          @row-clicked="onRowClick"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mx-1"></b-spinner>
            <strong>{{ msg('Loading') }}...</strong>
          </div>
        </template>
        <template #cell(creationTime)="data">
          <div>
            {{ $moment(data.value).format('DD/MM/YY HH:mm') }}
          </div>
        </template>
        <template #cell(imageUri)="data">
          <div>
            <b-img :src="data.value" fluid thumbnail style="max-width: 50px; max-height: 50px"/>
          </div>
        </template>
        <template #cell(active)="data">
          <div>
            <b-badge v-if="data.value" variant="success">{{ msg('Active') }}</b-badge>
            <b-badge v-else variant="danger">{{ msg('Inactive') }}</b-badge>
          </div>
        </template>
      </b-table>

      <div class="text-center w-100">
        <div class="d-inline-block">
          <b-pagination
              v-model="page.currentPage"
              :total-rows="page.total"
              :per-page="page.amount"
              first-number
              last-number
              @input="refresh"
          />
        </div>
        <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
      </div>
    </b-card>
    <guest-feature-modal ref="guestFeatureModal" @updated="refresh"/>
  </div>
</template>

<script>


import {mapActions, mapGetters} from "vuex";
import GuestFeatureModal from "@/components/modals/feature/GuestFeatureModal.vue";

export default {
  name: "guestFeature",
  components: {GuestFeatureModal},
  data: () => ({
    loading: true,
    filter: {},
    features: [],
    page: {amount: 10, currentPage: 1, total: 0},
  }),
  created() {
    this.refresh();
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    fields(){
      let $this = this
      return [
        {
          key: 'imageUri',
          label: $this.msg('imageUri'),
          sortable: false
        },
        {
          key: 'name',
          label: $this.msg('name'),
          sortable: true
        },
        {
          key: 'description',
          label: $this.msg('description'),
          sortable: false
        },
        {
          key: 'creationTime',
          label: $this.msg('Creation Time'),
          sortable: true
        },
        {
          key: 'active',
          label: $this.msg('active'),
          sortable: true
        },
      ]
    }
  },
  methods: {
    ...mapActions('guestFeature',['fetchAllGuestFeature']),
    refresh() {
      let $this = this;
      this.loading = true;
      this.fetchAllGuestFeature({
        page: this.page.currentPage,
        amount: this.page.amount,
        filter: this.filter
      }).then(data=>{
        console.log("refresh features", data);
        $this.features = data.Features;
        $this.page.amount = data['Amount']
        $this.page.total = data['Total']
        $this.page.currentPage = data['Page']
      }).finally(() => {
        $this.loading = false;
      });
    },
    addNewFeature() {
      this.$refs.guestFeatureModal.addNewFeature();
    },
    onRowClick(feature) {
      this.$refs.guestFeatureModal.updateFeature(feature.id);
    }
  }
}
</script>


<style scoped>

</style>
